import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../components/Loading";
import PrivateRoutes from "./PrivateRoutes";
import { useSelector } from "react-redux";
import Profile from "../pages/Profile/Profile";
import Cart from "../pages/Cart/Cart";
import NotFound from "../pages/NotFound/NotFound";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Email from "../pages/ResetPassword/Email";
// import Home from "../pages/Home/Home";
// import AboutUs from "../pages/AboutUs/AboutUs";
// import ContactUs from "../pages/ContactUs/ContactUs";
// import TermsConditions from "../pages/TermsConditions/TermsCondtions";
// import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
// import Register from "../pages/Register/Register";
// import Products from "../pages/Products/Products";
// import ProductDetail from "../pages/ProductDetail/ProductDetail";

const Home = lazy(() => import("../pages/Home/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs"));
const TermsConditions = lazy(() =>
  import("../pages/TermsConditions/TermsCondtions")
);
const PrivacyPolicy = lazy(() =>
  import("../pages/PrivacyPolicy/PrivacyPolicy")
);
const Register = lazy(() => import("../pages/Register/Register"));
const Products = lazy(() => import("../pages/Products/Products"));
const ProductDetail = lazy(() =>
  import("../pages/ProductDetail/ProductDetail")
);
// const Profile = lazy(() => import("../pages/Profile/Profile"));

const AppRoutes = () => {
  const { common } = useSelector(state => state.commonStore);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/about-us"
          element={
            <Suspense fallback={<Loading />}>
              <AboutUs />{" "}
            </Suspense>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Suspense fallback={<Loading />}>
              <ContactUs />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Loading />}>
              <PrivacyPolicy />{" "}
            </Suspense>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Suspense fallback={<Loading />}>
              <TermsConditions />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Loading />}>
              <Register />
            </Suspense>
          }
        />
        {common?.category?.map((cate, index) => {
          const path = cate?.name_en.toLowerCase();

          const id = cate.id;

          return (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<Loading />}>
                  <Products id={id} />
                </Suspense>
              }
            />
          );
        })}

        <Route
          path="product-detail/:sku"
          element={
            <Suspense fallback={<Loading />}>
              <ProductDetail />
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoutes>
              <Profile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/cart"
          element={
            <Suspense fallback={<Loading />}>
              <Cart />
            </Suspense>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/send-email" element={<Email />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AboutUsService from "../../services/AboutUsService";


export const getAboutUsData = createAsyncThunk(
  "aboutus/getAboutUsData",
  async () => {
    try {
      const res = await AboutUsService.getAboutUsData();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  aboutusObj: null,
};

const aboutUsSlice = createSlice({
  name: "aboutus",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAboutUsData.pending, state => {
        state.loading = true;
      })
      .addCase(getAboutUsData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.aboutusObj = payload;
      })
      .addCase(getAboutUsData.rejected, state => {
        state.loading = false;
      });
  },
});
export default aboutUsSlice.reducer;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import arrowSvg from "../../assets/images/arrow.svg";
import * as Yup from "yup";
import {
  addCartItem,
  applyCoupon,
  deleteCartItem,
  getCartPage,
  getUserCart,
  guestCouponApply,
  guestRemoveCoupon,
  handleFavorite,
  quantityChange,
  removeCoupon,
  removeFromCart,
} from "../../store/slices/guestCartSlice";
import { Form, Modal } from "react-bootstrap";
import AddAddress from "../../components/Forms/AddAddress";
import HelmetBase from "../../components/Helmet";
import StarsRating from "react-star-rate";
import { Link } from "react-router-dom";
import { addUserWishlist, getUserProfile } from "../../store/slices/userSlice";
import moment from "moment";
// Import Images
import iconCheckmark from "../../assets/images/iconCheckmark.png";
import arrowWhite from "../../assets/images/arrow.svg";
import { dropdownToggle } from "../../store/slices/commonSlice";
import { useFormik } from "formik";
import {
  checkVerificationCode,
  generateGuestOrder,
  generateOrder,
  sendVerificationCode,
  showModal,
} from "../../store/slices/placeOrderSlice";
import { RWebShare } from "react-web-share";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";
import Swal from "sweetalert2";
import CartService from "../../services/CartService";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Cart = () => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    address: Yup.string().required(t("addressSelectionisrequired")),
    email: Yup.string()
      .email(t("enteravalidemail"))
      .matches(emailRegex, t("emailnotallowed"))
      .trim()
      .required(t("pleaseenteryouremail"))
      .max(35),
    fullName: Yup.string().trim().required(t("fullNamerequired")),
    mobileNumber: Yup.string()
      .trim()
      .matches(phoneRegExp, t("phonenumberisnotvalid"))
      .min(9, t("adddigitmobilenumber"))
      .max(12, t("adddigitmobilenumber1"))
      .required(t("mobilenumberisRequired")),
    // code: Yup.string().when("email", {
    //   is: email => !!email,
    //   then: Yup.string().trim().required(t("verificationcodeisrequired")),
    //   otherwise: Yup.string().trim().required(t("verificationcodeisrequired")),
    // }),
  });

  const formValidationsVeerify = Yup.object({
    email: Yup.string()
      .email(t("enteravalidemail"))
      .matches(emailRegex, t("emailnotallowed"))
      .trim()
      .required(t("pleaseenteryouremail"))
      .max(35),
    fullName: Yup.string().trim().required(t("fullNamerequired")),
    mobileNumber: Yup.string()
      .trim()
      .matches(phoneRegExp, t("phonenumberisnotvalid"))
      .min(9, t("adddigitmobilenumber"))
      .max(12, t("adddigitmobilenumber1"))
      .required(t("mobilenumberisRequired")),
  });

  const formValidiationCode = Yup.object({
    code: Yup.string().trim().required(t("verificationcodeisrequired")),
  });

  // const [placeOrderModal, setPlaceOrderModalShow] = useState(false);
  // const handlePlaceOrderClose = () => setPlaceOrderModalShow(false);
  // const handlePlaceOrderShow = id => {
  //   setPlaceOrderModalShow(true);
  // };

  const token = localStorage.getItem("token");

  const {
    guestcart,
    address,
    total,
    cartPage,
    cart,
    related_items,
    total_amount,
    sub_total_amount,
    shipping_amount,
    cart_id,
    discount,
    coupon_code,
    guest_sub_total,
    coupon_amount,
  } = useSelector(state => state.guestCartStore);

  const { user, loadingWishList } = useSelector(state => state.userStore);

  const { common } = useSelector(state => state.commonStore);

  const { order, showOrderModal } = useSelector(state => state.placeOrderStore);

  const dispatch = useDispatch();

  const [showAddAddressModal, setAddAddressModalShow] = useState(false);

  const handleAddAddressClose = () => setAddAddressModalShow(false);
  const handleAddAddressShow = () => setAddAddressModalShow(true);

  const formikFormVerify = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      mobileNumber: "",
    },
    validationSchema: formValidationsVeerify,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append("fullName", values.fullName);
      formData.append("email", values.email);
      formData.append("mobileNumber", values.mobileNumber);

      dispatch(sendVerificationCode(formData));
    },
  });

  const formikFormCode = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: formValidiationCode,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append("email", formikFormVerify.values.email);
      formData.append("otp", values.code);

      dispatch(checkVerificationCode(formData));
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: "",
      coupon_code: coupon_code ? coupon_code : "",
      email: token
        ? user?.email
        : formikFormVerify.values.email
        ? formikFormVerify.values.email
        : "",
      fullName: token
        ? user?.name
        : formikFormVerify.values.fullName
        ? formikFormVerify.values.fullName
        : "",
      mobileNumber: token
        ? user?.phone_number
        : formikFormVerify.values.mobileNumber
        ? formikFormVerify.values.mobileNumber
        : "",
      // code: formikFormCode.values.code ? formikFormCode.values.code : "",
      token: token || "",
    },
    validationSchema: formValidations,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      if (token) {
        formData.append("address_id", values.address);
        formData.append("coupon_code", values.coupon_code);
        await dispatch(generateOrder(formData));

        // handlePlaceOrderShow(true);

        setSubmitting(false);
        resetForm();
        dispatch(getUserCart());
      } else {
        if (
          !_.isEmpty(formikFormVerify.values.email) &&
          !_.isEmpty(formikFormVerify.values.mobileNumber) &&
          !_.isEmpty(formikFormVerify.values.fullName) &&
          !_.isEmpty(formikFormCode.values.code)
        ) {
          const addressObj = address?.find(
            add => add.id === Number(values.address)
          );
          formData.append("name", formikFormVerify.values.fullName);
          formData.append("email", formikFormVerify.values.email);
          formData.append("phone_number", formikFormVerify.values.mobileNumber);
          formData.append("apartment_number", addressObj.apartmentNumber);
          formData.append("building_name", addressObj.buildingName);
          formData.append("street_name", addressObj.streetName);
          formData.append("area", addressObj.area);
          formData.append("coupon_code", values.coupon_code);

          guestcart?.forEach((cart, index) => {
            formData.append(`product_id[${index}]`, cart.id);
            formData.append(`size_id[${index}]`, cart.size);
            formData.append(`color_id[${index}]`, cart.color);
            formData.append(`price[${index}]`, cart.selling_price);
            formData.append(`qty[${index}]`, cart.quantity);
          });

          dispatch(generateGuestOrder(formData));

          // handlePlaceOrderShow(true);
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "Please Fill the Above Requriements",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
  });

  useEffect(() => {
    dispatch(getCartPage());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getUserCart());
      dispatch(getUserProfile());
    }
  }, [dispatch]);

  const deleteItem = async item => {
    let formData = new FormData();
    formData.append("cart_id", cart_id);
    formData.append("product_id", item.product_id);
    formData.append("color_id", item.color_id);
    formData.append("size_id", item.size_id);

    dispatch(
      deleteCartItem({
        data: formData,
        product_id: item.product_id,
        size_id: item.size_id,
        color_id: item.color_id,
      })
    );
    if (coupon_code) {
      let formdata = new FormData();

      formdata.append("cart_id", cart_id);
      formdata.append("coupon_code", formik.values.coupon_code);

      CartService.removeCoupon(formdata);
    }
  };

  const addProductToWishlist = productId => {
    let formData = new FormData();
    formData.append("product_id", productId);
    formData.append("status", 1);
    if (token) {
      dispatch(addUserWishlist(formData));
    } else {
      dispatch(dropdownToggle());
    }
  };

  const handleQuantityChange = (e, item) => {
    const { value } = e.target;
    if (!token) {
      item = {
        ...item,
        quantity: value,
      };
      sessionStorage.removeItem("discount");
      sessionStorage.removeItem("coupon");
      dispatch(quantityChange(item));
    } else {
      let formData = new FormData();

      formData.append("product_id", item.product_id);
      formData.append("size_id", item.size_id);
      formData.append("color_id", item.color_id);
      formData.append("qty", value);

      dispatch(addCartItem(formData));
      removeCouponCode();
    }
  };

  const favorite = (id, favorite) => {
    let prdts;
    let formData = new FormData();

    if (token) {
      if (favorite === 1) {
        prdts = related_items?.map(item => {
          return item.id === id ? { ...item, is_favorite: 0 } : item;
        });
        dispatch(handleFavorite(prdts));
        formData.append("product_id", id);
        formData.append("status", 0);
      } else {
        prdts = related_items?.map(item => {
          return item.id === id ? { ...item, is_favorite: 1 } : item;
        });
        formData.append("product_id", id);
        formData.append("status", 1);

        dispatch(handleFavorite(prdts));
      }
      dispatch(addUserWishlist(formData));
    } else {
      dispatch(dropdownToggle());
      window.scrollTo(0, 0);
    }
  };

  const checkCouponCode = async () => {
    if (token) {
      let formdata = new FormData();
      if (formik.values.coupon_code.length > 0) {
        formdata.append("cart_id", cart_id);
        formdata.append("coupon_code", formik.values.coupon_code);
        dispatch(applyCoupon(formdata));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please enter coupon code",
          timer: "2000",
          showConfirmButton: false,
        });
      }
    } else {
      let couponCode = null;
      let discount = 0;
      let findItem = common?.coupons?.find(
        coupon => coupon.code === formik.values.coupon_code
      );

      if (findItem) {
        couponCode = findItem.code;
        discount = findItem.value;

        dispatch(guestCouponApply({ couponCode, discount }));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Coupon verfired successfully",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Coupon verfired successfully",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const removeCouponCode = async () => {
    if (token) {
      let formdata = new FormData();

      formdata.append("cart_id", cart_id);
      formdata.append("coupon_code", formik.values.coupon_code);
      dispatch(removeCoupon(formdata));
      sessionStorage.removeItem("coupon");
    } else {
      dispatch(guestRemoveCoupon());
    }
  };

  return (
    <>
      <HelmetBase
        title={cartPage?.meta_title}
        name={cartPage?.name_en}
        description={cartPage?.meta_description}
      />
      {/* <!-- Page Heading --> */}
      <section className="section-page-heading">
        <div className="page-heading-watermark">{t("cart")}</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                <h5>
                  {i18n.language == "en"
                    ? cartPage?.heading_en
                    : cartPage?.heading_ar}
                </h5>
                <h1>{t("cart")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Heading --> */}

      {/* <!-- Section Profile --> */}
      <section className="section-single-product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
              {!token && guestcart?.length > 0 ? (
                guestcart?.map((item, index) => {
                  return (
                    <div className="cart-itembox" key={index}>
                      <div className="cart-item-image">
                        <img src={item.image} className="img-fluid" alt="" />
                      </div>
                      <div className="cart-item-content">
                        <div>
                          <h6 className="text-uppercase text-primary font-small">
                            {i18n.language
                              ? item.category_name_en
                              : item.category_name_ar}
                          </h6>
                          <h2>
                            {i18n.language == "en"
                              ? item?.name_en?.length > 15
                                ? item?.name_en?.slice(0, 15) + ".."
                                : item?.name_en
                              : item?.name_ar?.length > 15
                              ? item?.name_ar?.slice(0, 15) + ".."
                              : item?.name_ar}
                          </h2>
                          <h3 className="d-flex align-items-center">
                            aed {item.selling_price}{" "}
                            <span>{t("inclusiveOfVat")}</span>
                          </h3>
                        </div>
                        <div className="quantity-btns">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e => handleQuantityChange(e, item)}
                            value={item.quantity}
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </select>
                          <button
                            type="button"
                            className="btn-cart"
                            onClick={() => dispatch(removeFromCart(item))}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                          <button
                            type="button"
                            className="add-to-wishlist"
                            onClick={() => {
                              dispatch(dropdownToggle());
                            }}
                          >
                            {t("movetowishlist")}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : token && cart?.length > 0 ? (
                cart?.map((item, index) => {
                  return (
                    <div className="cart-itembox" key={index}>
                      <div className="cart-item-image">
                        <img src={item?.image} className="img-fluid" alt="" />
                      </div>
                      <div className="cart-item-content">
                        <div>
                          <h6 className="text-uppercase text-primary font-small">
                            {i18n.language
                              ? item?.category_name_en
                              : item?.category_name_ar}
                          </h6>
                          <h2>
                            {i18n.language == "en"
                              ? item?.name_en?.length > 15
                                ? item?.name_en?.slice(0, 15) + ".."
                                : item?.name_en
                              : item?.name_ar?.length > 15
                              ? item?.name_ar?.slice(0, 15) + ".."
                              : item?.name_ar}
                          </h2>
                          <h3 className="d-flex align-items-center">
                            aed {item?.selling_price}{" "}
                            <span>{t("inclusiveOfVat")}</span>
                          </h3>
                        </div>
                        <div className="quantity-btns">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e => handleQuantityChange(e, item)}
                            value={item?.qty}
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </select>
                          <button
                            type="button"
                            className="btn-cart"
                            onClick={() => deleteItem(item)}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                          <button
                            type="button"
                            className="add-to-wishlist"
                            onClick={() =>
                              addProductToWishlist(item.product_id)
                            }
                          >
                            {!loadingWishList
                              ? t("movetowishlist")
                              : "Loading..."}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3 className="text-light">{t("noItemsFound")}</h3>
              )}
            </div>
            <div className="col-sm-5">
              {!token && guestcart?.length > 0 && (
                <div className="common-box">
                  <Form onSubmit={formikFormVerify.handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">{t("fullName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="fullName"
                        value={formikFormVerify.values.fullName}
                        onChange={formikFormVerify.handleChange}
                      />
                      {(formikFormVerify.touched.fullName &&
                        formikFormVerify.errors.fullName && (
                          <div className="text-danger">
                            {formikFormVerify.errors.fullName}
                          </div>
                        )) ||
                        (formik.touched.fullName && formik.errors.fullName && (
                          <div className="text-danger">
                            {formik.errors.fullName}
                          </div>
                        ))}
                      {}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">{t("mobileNumber")}</label>
                      <input
                        type="phone"
                        className="form-control"
                        name="mobileNumber"
                        value={formikFormVerify.values.mobileNumber}
                        onChange={formikFormVerify.handleChange}
                      />
                      {(formikFormVerify.touched.mobileNumber &&
                        formikFormVerify.errors.mobileNumber && (
                          <div className="text-danger">
                            {formikFormVerify.errors.mobileNumber}
                          </div>
                        )) ||
                        (formik.touched.mobileNumber &&
                          formik.errors.mobileNumber && (
                            <div className="text-danger">
                              {formik.errors.mobileNumber}
                            </div>
                          ))}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">{t("email")}</label>

                      <div className="row gx-2 align-items-center">
                        <div className="col-md-7">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={formikFormVerify.values.email}
                            onChange={formikFormVerify.handleChange}
                          />
                          {(formikFormVerify.touched.email &&
                            formikFormVerify.errors.email && (
                              <div className="text-danger">
                                {formikFormVerify.errors.email}
                              </div>
                            )) ||
                            (formik.touched.email && formik.errors.email && (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            ))}
                        </div>
                        <div className="col-md-5">
                          <button type="submit" className="btn cstm-btn">
                            {t("send")} {t("verificationCode")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <Form onSubmit={formikFormCode.handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">
                        {t("verificationCode")} {t("number")}
                      </label>
                      <div className="row align-items-center">
                        <div className="col-md-10">
                          <input
                            type="text"
                            className="form-control"
                            name="code"
                            value={formikFormCode.values.code}
                            onChange={formikFormCode.handleChange}
                          />
                          {formikFormCode.touched.code &&
                            formikFormCode.errors.code && (
                              <div className="text-danger">
                                {formikFormCode.errors.code}
                              </div>
                            )}
                        </div>
                        <div className="col-md-2">
                          <button type="submit" className="checkmark">
                            <i className="fa-solid fa-circle-check"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
              {guestcart?.length > 0 || cart?.length > 0 ? (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="common-box">
                    <ul className="address-selection">
                      {!token
                        ? address?.map((add, index) => {
                            return (
                              <li key={index}>
                                <input
                                  type="radio"
                                  name="address"
                                  id={index}
                                  value={add.id}
                                  onChange={e => {
                                    formik.setFieldValue(
                                      "address",
                                      e.currentTarget.value
                                    );
                                  }}
                                  checked={
                                    String(formik.values.address) ==
                                    String(add.id)
                                  }
                                />
                                <label htmlFor={index}>
                                  <h5>
                                    {t("address")} {index + 1}
                                  </h5>
                                  <div>
                                    {add.apartmentNumber}, {add.buildingName},{" "}
                                    {add.streetName},{add.area}.
                                  </div>
                                </label>
                              </li>
                            );
                          })
                        : user?.addresss?.map((add, index) => {
                            return (
                              <li key={index}>
                                <input
                                  type="radio"
                                  name="address"
                                  id={index}
                                  value={add.id}
                                  onChange={e => {
                                    formik.setFieldValue(
                                      "address",
                                      e.currentTarget.value
                                    );
                                  }}
                                  checked={
                                    Number(formik.values.address) == add.id
                                  }
                                />
                                <label htmlFor={index}>
                                  <h5>
                                    {t("address")} {index + 1}
                                  </h5>
                                  <div>
                                    {add.apartment_number}, {add.building_name},{" "}
                                    {add.street_name},{add.area}.
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                    </ul>
                    {formik.touched.address && formik.errors.address && (
                      <div className="text-danger">{formik.errors.address}</div>
                    )}
                    <div className="text-end">
                      <a
                        className="btn btn-outline-light arrow-animation"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        onClick={handleAddAddressShow}
                      >
                        {t("add")} {t("address")}{" "}
                        <span class="arrow arrow-black"></span>
                      </a>
                    </div>
                  </div>

                  <div className="common-box">
                    <div className="coupon-code-field">
                      <label>{t("paymentMethod")}</label>
                      <div className="cod">{t("cashOnDelivery")}</div>
                    </div>
                  </div>
                  <div className="common-box">
                    <div className="coupon-code-field">
                      <label>{t("enterCouponCode")}:</label>
                      <div className="row">
                        <div className="col-md-10">
                          <input
                            type="text"
                            className="form-control"
                            name="coupon_code"
                            disabled={coupon_code ? true : false}
                            value={formik.values.coupon_code}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="col-md-2">
                          {!coupon_code && !discount ? (
                            <button
                              type="button"
                              className="checkmark"
                              onClick={() => checkCouponCode()}
                            >
                              <i className="fa-solid fa-circle-check"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="checkmark text-danger"
                              onClick={() => removeCouponCode()}
                            >
                              <i className="fa-solid fa-circle-xmark"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="cart-total-calculations">
                      <div className="item-delivery-date">
                        {t("deliveryDate")}:
                        <span>
                          {moment()
                            .add(common?.estimated_delivery_day, "days")
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="item-pricing">
                        <ul>
                          <li>
                            <div>
                              {t("subtotal")}{" "}
                              <span>
                                (
                                {!token
                                  ? `${guestcart?.length} Items`
                                  : `${cart?.length} Items`}
                                )
                              </span>
                            </div>{" "}
                            <div className="item-value">
                              AED{" "}
                              {!token
                                ? Number(guest_sub_total).toFixed(2)
                                : sub_total_amount}
                            </div>
                          </li>
                          <li className="discount">
                            <div>{t("discount")}</div>{" "}
                            <div className="item-value">
                              - AED{" "}
                              {!token
                                ? discount
                                  ? (
                                      (Number(guest_sub_total) *
                                        Number(discount)) /
                                      100
                                    ).toFixed(2)
                                  : "0.00"
                                : coupon_amount}
                            </div>
                          </li>
                          <li>
                            <div>{t("shippingFee")}</div>{" "}
                            <div className="item-value">
                              AED{" "}
                              {token ? shipping_amount : common?.shipping_fee}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="cart-item-total-price text-end">
                      {t("total")} <span>({t("inclusiveOfVat")})</span>{" "}
                      <div className="total-price">
                        AED{" "}
                        {!token
                          ? (
                              Number(total) + Number(common?.shipping_fee)
                            ).toFixed(2)
                          : total_amount}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary arrow-animation w-100"
                  >
                    {t("placeOrder")} <span class="arrow arrow-black"></span>
                  </button>
                </Form>
              ) : (
                <></>
              )}
            </div>
            <div className="col-sm-3">
              <div className="delivery-notes">
                <h5>{t("deliveryNotes")}:</h5>
                <ul>
                  {common?.delivery_notes?.map((notes, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {i18n.language == "en"
                            ? notes?.name_en
                            : notes?.name_ar}
                        </span>
                        {i18n.language == "en"
                          ? notes?.description_en
                          : notes?.description_ar}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Profile --> */}

      {/* <!-- Section Wishlist --> */}
      {token && (
        <section className="section-wishlist">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="title mb-4 text-white">
                  <h6 className="text-uppercase text-primary">
                    {" "}
                    {i18n.language == "en"
                      ? cartPage?.heading_en
                      : cartPage?.heading_ar}
                  </h6>
                  <h2 className="text-uppercase">{t("itemsYouMightlike")}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <Swiper
                spaceBetween={50}
                modules={[Navigation, Autoplay]}
                slidesPerView={1}
                dir={i18n.language == "en" ? "ltr" : "rtl"}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
              >
                {related_items?.map((item, index) => {
                  let shareData = {
                    url: `/product-detail/${item.sku}`,
                  };
                  return (
                    <SwiperSlide className="col-sm-6 col-lg-3" key={index}>
                      <div className="prodcut-box">
                        <div className="product-image">
                          {item?.tag == "1" ? (
                            <div className="tag"> {t("new")}</div>
                          ) : item?.tag == "2" ? (
                            <div className="tag"> {t("sale")}</div>
                          ) : (
                            <></>
                          )}
                          <img
                            src={item.image}
                            className="img-fluid product-thumbnail"
                            alt=""
                          />
                          <div className="product-image-overlay">
                            <button
                              className="product-wishlist"
                              onClick={() =>
                                favorite(item.id, item.is_favorite)
                              }
                            >
                              <i
                                className={
                                  item.is_favorite == 1
                                    ? "fa-solid fa-heart"
                                    : "fa-regular fa-heart"
                                }
                              ></i>
                            </button>
                            <RWebShare data={shareData}>
                              <button className="product-share">
                                <i className="fa-solid fa-share-nodes"></i>
                              </button>
                            </RWebShare>
                            <div>
                              <Link
                                to={`/product-detail/${item.sku}`}
                                className="btn btn-outline-light arrow-animation"
                              >
                                {t("seeMore")}{" "}
                                <span class="arrow arrow-black"></span>
                              </Link>
                              <Link
                                to={`/product-detail/${item.sku}`}
                                className="btn btn-outline-light arrow-animation"
                              >
                                {t("addToCart")}{" "}
                                <span class="arrow arrow-black"></span>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="product-content">
                          <div className="product-category-rating">
                            <div className="product-category">
                              {i18n.language == "en"
                                ? item.category_name_en
                                : item.category_name_ar}
                            </div>
                            <div className="product-rating">
                              <StarsRating
                                value={Number(item?.avg_rating)}
                                disabled
                                classNamePrefix="product-rating"
                              />
                            </div>
                          </div>
                          <div className="product-title-price">
                            <h3 className="product-title">
                              <Link to={`/product-detail/${item.sku}`}>
                                {i18n.language == "en"
                                  ? item?.name_en?.length > 15
                                    ? item?.name_en?.slice(0, 15) + ".."
                                    : item?.name_en
                                  : item?.name_ar?.length > 15
                                  ? item?.name_ar?.slice(0, 15) + ".."
                                  : item?.name_ar}
                              </Link>
                            </h3>
                            <div className="product-price">
                              {Number(item?.regular_price) !=
                              Number(item?.selling_price) ? (
                                <>
                                  <div className="old-price">
                                    AED {item?.regular_price}
                                  </div>
                                  AED {item?.selling_price}
                                </>
                              ) : (
                                <> {item?.selling_price}</>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
      )}
      {/* <!-- Section Wishlist --> */}

      {/* Add Address Modal */}
      <Modal
        show={showAddAddressModal}
        onHide={handleAddAddressClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h5 className="modal-title" id="changePasswordLabel">
              <h5 className="modal-title" id="addnewAddressLabel">
                {t("add")} {t("new")} {t("address")}
              </h5>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAddress handleClose={handleAddAddressClose} />
        </Modal.Body>
      </Modal>
      {/* Add Address Modal */}

      {/* Place Order Modal */}
      <Modal
        show={showOrderModal}
        onHide={() => dispatch(showModal(false))()}
        centered
        size="lg"
        className="modalPlaceorder"
      >
        <Modal.Body className="text-center">
          <h5>{t("thankYouforYourOrder")}!</h5>
          <h2>
            {t("yourOrder")} #{order?.order_id} {t("isapproved")}!
          </h2>
          <ul className="order-overview-info">
            <li>
              {t("Estimated")} {t("deliveryDate")}:{" "}
              <span>{moment().add(7, "days").format("DD MMMM YYYY")}</span>
            </li>
            <li>
              {t("paymentMethod")}: <span>{t("cashOnDelivery")}</span>
            </li>
            <li>
              {t("shippingAddress")}:{" "}
              <span>
                {order?.apartment_number}, {order?.building_name},{" "}
                {order?.street_name}, {order?.area}{" "}
              </span>
            </li>
          </ul>
          <div className="cart-buttons-bar">
            <Link to="/" className="btn btn-outline-light arrow-animation mx-2">
              {t("backtoHomepage")} <span class="arrow"></span>
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      {/* Place Order Modal */}
    </>
  );
};

export default Cart;

import baseAPI from "../api/api";

const getContactUsData = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=3`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const post = async data => {
  try {
    const res = await baseAPI.post(`/add-contact`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const ContactUsService = {
  getContactUsData,
  post,
};

export default ContactUsService;

import baseAPI from "../api/api";

const getAboutUsData = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=2`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const AboutUsService = {
  getAboutUsData,
};

export default AboutUsService;

import baseAPI from "../api/api";
import { authHeader } from "../helpers/authHeaders";

const getProfilePage = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=7`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserProfile = async () => {
  try {
    const res = await baseAPI.get(`/get-profile`, { headers: authHeader() });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const updateUserProfile = async data => {
  try {
    const res = await baseAPI.post(`/update-profile`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const addUserWishlist = async data => {
  try {
    const res = await baseAPI.post(`/add-product-wishlist`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const changePassword = async data => {
  try {
    const res = await baseAPI.post(`/change-password`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getAddress = async () => {
  try {
    const res = await baseAPI.get(`/get-address`, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const addAddress = async data => {
  try {
    const res = await baseAPI.post(`/add-address`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getAddressById = async id => {
  try {
    const res = await baseAPI.get(`/get-address-details?address_id=${id}`, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const editAddress = async data => {
  try {
    const res = await baseAPI.post(`/update-address`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteAddress = async data => {
  try {
    const res = await baseAPI.post(`/delete-address`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const UserService = {
  getProfilePage,
  getUserProfile,
  updateUserProfile,
  addUserWishlist,
  changePassword,
  getAddress,
  addAddress,
  getAddressById,
  editAddress,
  deleteAddress,
};
export default UserService;

import React from "react";
// Import Images
import arrowWhite from "../../assets/images/arrow.svg";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetPassword } from "../../store/slices/registerUserSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    password: Yup.string()
      .trim()
      .required(t("passwordisrequired"))
      .min(8, t("minimumCharactersRequired")),
    confirmPassword: Yup.string()
      .required(t("confirmPasswordrequired"))
      .oneOf([Yup.ref("password"), null], t("passwordsmustmatch")),
  });

  const email = localStorage.getItem("email");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: formValidations,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", values.password);
      if (email) {
        dispatch(resetPassword(formData));
        setSubmitting(false);
        resetForm();
        localStorage.removeItem("email");
        navigate("/");
      }
    },
  });
  return (
    <>
      {/* <!-- Page Heading --> */}
      <section className="section-page-heading">
        <div className="page-heading-watermark">{t("resetPassword")}</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                <h5>&nbsp;</h5>
                <h1>&nbsp;</h1>
                <h4>&nbsp;</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Heading --> */}
      <section className="section-resetpassword">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 m-auto">
              <Form onSubmit={formik.handleSubmit}>
                <div className="form-resetpassword">
                  <div className="form-group">
                    <label>
                      {t("password")} <span>*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      {t("passwordConfirmation")} <span>*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="text-danger">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary arrow-animation"
                      type="submit"
                    >
                      {t("resetPassword")} <span class="arrow"></span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;

import Swal from "sweetalert2";
import baseAPI from "../api/api";

const registerPage = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=6`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const registerUser = async data => {
  try {
    const res = await baseAPI.post(`/register`, data);
    if (res) {
      Swal.fire({
        position: "center",
        title: "Congratulations, Your account has been successfully created.",
        icon: "success",
      });
    }
    return res.data;
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      title: "Account already exist with IXMOTO",
      icon: "warning",
    });
  }
};

const forgetPassword = async data => {
  try {
    const res = await baseAPI.post(`/forgot-password`, data);
    Swal.fire({
      title: "Reset password link has been sent to your registered email.",
      icon: "success",
      position: "center",
    });
    return res.data;
  } catch (error) {
    Swal.fire({
      title: "User Not Exists",
      icon: "warning",
      position: "center",
    });
    console.log(error);
  }
};

const resetPassword = async data => {
  try {
    const res = await baseAPI.post(`/reset-password`, data);
    Swal.fire({
      title: "Password Reset Successfully. You Can Login With New Password",
      icon: "success",
      position: "center",
    });

    return res.data;
  } catch (error) {
    Swal.fire({
      title: "Something Went Wrong",
      icon: "warning",
      position: "center",
    });
    console.log(error);
  }
};

const RegisterService = {
  registerUser,
  registerPage,
  resetPassword,
  forgetPassword,
};
export default RegisterService;

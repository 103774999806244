import baseAPI from "../api/api";
import { authHeader } from "../helpers/authHeaders";

const getHomeData = async () => {
  try {
    const res = await baseAPI.get(`/home`, { headers: authHeader() });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const HomeService = {
  getHomeData,
};
export default HomeService;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactUsService from "../../services/ContactUsService";
import Swal from "sweetalert2";

export const getContactUsData = createAsyncThunk(
  "contactus/getContactUsData",
  async () => {
    try {
      const res = await ContactUsService.getContactUsData();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addContact = createAsyncThunk(
  "contactus/addContact",
  async data => {
    try {
      const res = await ContactUsService.post(data);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Thank you for contacting us",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  contactusObj: null,
  contact: null,
};

const contactUsSlice = createSlice({
  name: "contactus",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getContactUsData.pending, state => {
        state.loading = true;
      })
      .addCase(getContactUsData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contactusObj = payload;
      })
      .addCase(getContactUsData.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(addContact.pending, state => {
        state.loading = true;
      })
      .addCase(addContact.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contact = payload;
      })
      .addCase(addContact.rejected, state => {
        state.loading = false;
      });
  },
});
export default contactUsSlice.reducer;

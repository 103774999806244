import Swal from "sweetalert2";
import baseAPI from "../api/api";
import { authHeader } from "../helpers/authHeaders";

const generateOrder = async data => {
  try {
    const res = await baseAPI.post(`/generate-order`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const sendVerificationCode = async data => {
  try {
    const res = await baseAPI.post(`/send-verification-code`, data);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Verification Code Send to Your Email",
      showConfirmButton: false,
      timer: 3000,
    });
    return res.data;
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Email or Number Already Exists",
      showConfirmButton: false,
      timer: 3000,
    });
    console.log(error);
  }
};

const checkVerificationCode = async data => {
  try {
    const res = await baseAPI.post(`/check-verification-code`, data);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "OTP Match Successfully",
      showConfirmButton: false,
      timer: 3000,
    });
    return res.data;
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "OTP Not Match",
      showConfirmButton: false,
      timer: 3000,
    });
    console.log(error);
  }
};

const generateGuestOrder = async data => {
  try {
    const res = await baseAPI.post(`/generate-guest-order`, data);
    if (res) {
      sessionStorage.removeItem("cart");
      sessionStorage.removeItem("total");
      sessionStorage.removeItem("coupon");
      sessionStorage.removeItem("discount");
      sessionStorage.removeItem("address");
      sessionStorage.removeItem("sub_total");
    }
    return res.data;
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Email or Phone Number Already Exists",
    });
    console.log(error);
  }
};

const PlaceOrderService = {
  generateOrder,
  sendVerificationCode,
  checkVerificationCode,
  generateGuestOrder,
};
export default PlaceOrderService;

import React, { useState } from "react";
import iconCart from "../assets/images/icon-cart.png";
import iconMenu from "../assets/images/menu.png";
import iconSearch from "../assets/images/icon-search.png";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, NavDropdown } from "react-bootstrap";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../context/AuthContext";
import { dropdownToggle } from "../store/slices/commonSlice";
import { getProductsSearch, setSearchShow } from "../store/slices/productSlice";
import _ from "lodash";
import Loading from "./Loading";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Header = () => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    email: Yup.string()
      .trim()
      .test("email", t("emailPhoneisinvalid"), value => {
        return emailRegex.test(value) || phoneRegExp.test(value);
      })
      .required(t("emailMobilenumberisRequired")),
    password: Yup.string()
      .trim()
      .required(t("passwordisrequired"))
      .min(8, t("minimumCharactersRequired")),
  });

  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [offshow, setShowOffcanvas] = useState(false);
  // const [searchShow, setSearchShow] = useState(false);

  const { loginAction, error, user, logOut, setError } = useAuth();

  const token = localStorage.getItem("token");

  const { common, toggleDropdown } = useSelector(state => state.commonStore);
  const { guestcart, cart } = useSelector(state => state.guestCartStore);
  const { searchProducts, loadingSearch, searchShow } = useSelector(
    state => state.productStore
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formValidations,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await loginAction(values);
        setSubmitting(false);
        resetForm();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const searchChange = e => {
    const { value } = e.target;
    dispatch(getProductsSearch(value));
  };

  const languageChange = text => {
    localStorage.setItem("language", text);
    window.location.reload();
  };

  const location = useLocation();

  return (
    <>
      <header
        id="header"
        className={`header ${location.pathname == "/" ? "header-home" : ""} `}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={common?.header_logo} className="img-fluid" />
            </Link>

            <div
              className={`collapse navbar-collapse`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname == "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/"
                    onClick={() => (setActive(false), setShow(!show))}
                  >
                    {t("home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about-us"
                    onClick={() => (setActive(false), setShow(!show))}
                  >
                    {t("aboutus")}
                  </Link>
                </li>
                <li className="nav-item">
                  <div
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown1"
                    role="button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    id="navbarDropdownMenuLink"
                    onMouseOver={() => setActive(!active)}
                  >
                    {t("products")}
                  </div>
                  <div
                    className={`dropdown-menu megamenu ${
                      active ? "show" : ""
                    } `}
                    aria-labelledby="navbarDropdownMenuLink"
                    onMouseLeave={() => setActive(false)}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-7">
                          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-5">
                            {common?.category?.map((cate, index) => {
                              return (
                                <div className="col" key={index}>
                                  <Link
                                    to={`/${cate.name_en.toLowerCase()}`}
                                    className="text-decoration-none"
                                    onClick={() => {
                                      setActive(false);
                                    }}
                                  >
                                    <h4>
                                      {i18n.language == "en"
                                        ? cate.name_en
                                        : cate.name_ar}
                                    </h4>
                                  </Link>
                                  <ul className="megamenu-links">
                                    {cate?.brands?.map((brand, keyIndex) => {
                                      return (
                                        <li key={keyIndex}>
                                          <Link
                                            to={`/${cate.name_en.toLowerCase()}?brand=${
                                              brand.id
                                            }`}
                                            onClick={() => {
                                              setActive(false);
                                              setShow(!show);
                                            }}
                                          >
                                            {i18n.language == "en"
                                              ? brand?.name_en?.length > 10
                                                ? brand?.name_en?.slice(0, 10) +
                                                  ".."
                                                : brand?.name_en
                                              : brand?.name_ar?.length > 10
                                              ? brand?.name_ar?.slice(0, 10) +
                                                ".."
                                              : brand?.name_ar}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="row gx-3">
                            {common?.products?.map((prd, index) => {
                              return (
                                <div className="col col-6 col-sm-4" key={index}>
                                  <Link
                                    to={`product-detail/${prd.sku}`}
                                    onClick={() => {
                                      setActive(!active);
                                    }}
                                    className="menu-product"
                                    style={{
                                      backgroundImage: `url(${prd.image})`,
                                    }}
                                  >
                                    {prd?.tag == "1" ? (
                                      <div className="menu-product-tag">
                                        {t("new")}
                                      </div>
                                    ) : prd?.tag == "2" ? (
                                      <div className="menu-product-tag">
                                        {t("sale")}
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="menu-product-content">
                                      <div className="menu-product-cat">
                                        {i18n.language == "en"
                                          ? prd?.category_name_en
                                          : prd?.category_name_ar}
                                      </div>
                                      <h5>
                                        {i18n.language == "en"
                                          ? prd?.name_en
                                          : prd?.name_ar}
                                      </h5>
                                      <div className="menu-product-price">
                                        AED{prd?.selling_price}
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    onClick={() => (setActive(false), setShow(!show))}
                  >
                    {t("contactus")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="header-right d-flex align-items-center me-auto">
              <Link to="/cart" className="cart-item-number">
                <img
                  src={iconCart}
                  className="img-fluid"
                  alt=""
                  onClick={() => setActive(false)}
                />
                {!token ? (
                  <span>{guestcart?.length > 0 ? guestcart?.length : 0}</span>
                ) : (
                  <span>{cart?.length > 0 ? cart?.length : 0}</span>
                )}
              </Link>
              <div
                className="searchbox"
                onMouseLeave={() =>
                  searchProducts?.length > 0
                    ? null
                    : dispatch(setSearchShow(false))
                }
              >
                {searchShow ? (
                  <>
                    <input
                      placeholder="Search..."
                      className="form-control"
                      type="text"
                      onChange={e => searchChange(e)}
                    />
                    {searchProducts?.length > 0 && (
                      <div className="searched-items">
                        {!loadingSearch ? (
                          searchProducts?.map((s, index) => {
                            return (
                              <Link
                                to={`/product-detail/${s.sku}`}
                                onClick={() => dispatch(setSearchShow(false))}
                                key={index}
                              >
                                <span>
                                  <img src={s.image} className="img-fluid" />
                                </span>
                                {i18n.language ? s.name_en : s.name_ar}
                              </Link>
                            );
                          })
                        ) : (
                          <Loading />
                        )}
                      </div>
                    )}
                  </>
                ) : null}
                {/* <Link> */}
                <img
                  src={iconSearch}
                  className="img-fluid"
                  alt="img"
                  style={{ position: "relative", zIndex: "1" }}
                  onMouseOver={() => dispatch(setSearchShow(true))}
                />
                {/* </Link> */}
              </div>
              {!token ? (
                <Dropdown
                  className="myaccount dropdown"
                  show={toggleDropdown}
                  onToggle={() => dispatch(dropdownToggle(), setError(null))}
                >
                  <Dropdown.Toggle
                    className="nav-link dropdown-toggle bg-transparent border-0"
                    id="myaccount"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("login")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu account-box">
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="form-field">
                        <label>{t("emailorMobileNumber")}</label>
                        <input
                          type="text"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          className="form-control"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-field">
                        <label>{t("password")}</label>
                        <input
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          className="form-control"
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="text-danger">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div className="text-danger">{error ? error : null}</div>
                      <div className="form-field">
                        <button
                          className="btn btn-primary btn-sm w-100"
                          type="submit"
                        >
                          {t("login")}
                        </button>
                      </div>
                      <div className="form-field">
                        <Link
                          to="/send-email"
                          onClick={() =>
                            dispatch(dropdownToggle(), setError(null))
                          }
                        >
                          {t("forgotPassword")}?
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="/register"
                          className="btn btn-sm btn-light w-100"
                          onClick={() =>
                            dispatch(dropdownToggle(), setError(null))
                          }
                        >
                          {t("register")}
                        </Link>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown className="myaccount">
                  <Dropdown.Toggle
                    className="nav-link dropdown-toggle bg-transparent border-0"
                    id="myaccount"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user?.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu account-box">
                    <div className="dropdown-item">
                      <Link to="/profile" className="nav-link">
                        {t("profile")}
                      </Link>
                    </div>
                    <div className="dropdown-item ">
                      <Link to="/" onClick={logOut} className="nav-link">
                        {t("logout")}
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Dropdown className="dropdown-lang">
                <Dropdown.Toggle
                  className="nav-link dropdown-toggle bg-transparent border-0"
                  id="dropdown-basic"
                >
                  {i18n.language == "ar" ? "AR" : "EN"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link
                    className="nav-link"
                    onClick={() => languageChange("en")}
                  >
                    EN
                  </Link>

                  <Link
                    className="nav-link"
                    onClick={() => languageChange("ar")}
                  >
                    AR
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              onClick={() => setShowOffcanvas(!offshow)}
            >
              <img src={iconMenu} className="img-fluid" alt="" />
            </button>
          </div>
        </nav>
      </header>

      {/* Mobile Canvas Menu */}
      <div
        className={`offcanvas offcanvas-start ${offshow ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {t("menu")}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setShowOffcanvas(!offshow)}
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav m-auto">
            <li className="nav-item">
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
                onClick={() => setShowOffcanvas(!offshow)}
              >
                {t("home")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/about-us"
                onClick={() => setShowOffcanvas(!offshow)}
              >
                {t("aboutus")}
              </Link>
            </li>
            <li className="nav-item">
              <div
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown1"
                role="button"
                aria-expanded="false"
                aria-haspopup="true"
                id="navbarDropdownMenuLink"
                onClick={() => setActive(!active)}
              >
                {t("products")}
              </div>
              <div
                className={`dropdown-menu megamenu ${active ? "show" : ""} `}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-5">
                        {common?.category?.map((cate, index) => {
                          return (
                            <div className="col" key={index}>
                              <Link
                                to={`${cate.name_en.toLowerCase()}`}
                                className="text-decoration-none"
                                onClick={() => {
                                  setActive(!active);
                                  setShowOffcanvas(!offshow);
                                }}
                              >
                                <h4>
                                  {i18n.language == "en"
                                    ? cate.name_en
                                    : cate.name_ar}
                                </h4>
                              </Link>
                              <ul className="megamenu-links">
                                {cate?.brands?.map((brand, keyIndex) => {
                                  return (
                                    <li key={keyIndex}>
                                      <Link
                                        to={`/${cate.name_en.toLowerCase()}?brand=${
                                          brand.id
                                        }`}
                                        onClick={() => {
                                          setActive(!active);
                                          setShowOffcanvas(!offshow);
                                        }}
                                      >
                                        {i18n.language == "en"
                                          ? brand?.name_en?.length > 10
                                            ? brand?.name_en?.slice(0, 10) +
                                              ".."
                                            : brand?.name_en
                                          : brand?.name_ar?.length > 10
                                          ? brand?.name_ar?.slice(0, 10) + ".."
                                          : brand?.name_ar}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="row gx-3">
                        {common?.products?.map((prd, index) => {
                          return (
                            <div className="col col-6 col-sm-4" key={index}>
                              <Link
                                to={`product-detail/${prd.id}`}
                                onClick={() => {
                                  setActive(!active);
                                  setShowOffcanvas(!offshow);
                                }}
                                className="menu-product"
                                style={{
                                  backgroundImage: `url(${prd.image})`,
                                }}
                              >
                                {prd?.tag == "1" ? (
                                  <div className="menu-product-tag">
                                    {t("new")}
                                  </div>
                                ) : prd?.tag == "2" ? (
                                  <div className="menu-product-tag">
                                    {t("sale")}
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div className="menu-product-content">
                                  <div className="menu-product-cat">
                                    {i18n.language == "en"
                                      ? prd?.category_name_en
                                      : prd?.category_name_ar}
                                  </div>
                                  <h5>
                                    {i18n.language == "en"
                                      ? prd?.name_en
                                      : prd?.name_ar}
                                  </h5>
                                  <div className="menu-product-price">
                                    AED{prd?.selling_price}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/contact-us"
                onClick={() => (setActive(false), setShowOffcanvas(!offshow))}
              >
                {t("contactus")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PlaceOrderService from "../../services/PlaceOrderService";
import { removeAllCartItems } from "./guestCartSlice";

export const generateOrder = createAsyncThunk(
  "placeorder/generateOrder",
  async (data, thunkAPI) => {
    try {
      const res = await PlaceOrderService.generateOrder(data);
      if (res) {
        thunkAPI.dispatch(showModal(true));
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const generateGuestOrder = createAsyncThunk(
  "placeorder/generateGuestOrder",
  async (data, thunkAPI) => {
    try {
      const res = await PlaceOrderService.generateGuestOrder(data);
      if (res) {
        thunkAPI.dispatch(removeAllCartItems());
        thunkAPI.dispatch(showModal(true));
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const checkVerificationCode = createAsyncThunk(
  "placeorder/checkVerificationCode",
  async data => {
    try {
      const res = await PlaceOrderService.checkVerificationCode(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendVerificationCode = createAsyncThunk(
  "placeorder/sendVerificationCode",
  async data => {
    try {
      const res = await PlaceOrderService.sendVerificationCode(data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  order: null,
  orderMessage: null,
  otpMessage: null,
  checkOtpMessage: null,
  showOrderModal: null,
};

const placeOrderSlice = createSlice({
  name: "placeorder",
  initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.showOrderModal = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(generateOrder.pending, state => {
        state.loading = true;
      })
      .addCase(generateOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.order = payload;
      })
      .addCase(generateOrder.rejected, state => {
        state.loading = false;
      });

    // SEND VERIFICATION CODE

    builder
      .addCase(sendVerificationCode.pending, state => {
        state.loading = true;
      })
      .addCase(sendVerificationCode.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.otpMessage = payload;
      })
      .addCase(sendVerificationCode.rejected, state => {
        state.loading = false;
      });

    // CHECK OTP VERIFICATION

    builder
      .addCase(checkVerificationCode.pending, state => {
        state.loading = true;
      })
      .addCase(checkVerificationCode.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.otpMessage = payload;
      })
      .addCase(checkVerificationCode.rejected, state => {
        state.loading = false;
      });

    // GENERATE GUEST ORDER

    builder
      .addCase(generateGuestOrder.pending, state => {
        state.loading = true;
      })
      .addCase(generateGuestOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.order = payload;
      })
      .addCase(generateGuestOrder.rejected, state => {
        state.loading = false;
      });
  },
});

export const { showModal } = placeOrderSlice.actions;
export default placeOrderSlice.reducer;

import baseAPI from "../api/api";
import { authHeader } from "../helpers/authHeaders";

const getCartPage = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=10`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserCart = async () => {
  try {
    const res = await baseAPI.get(`/cart-list`, { headers: authHeader() });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const addCartItem = async data => {
  try {
    const res = await baseAPI.post(`/add-to-cart`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteCartItem = async data => {
  try {
    const res = await baseAPI.post(`/delete-cart-item`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const applyCoupon = async data => {
  try {
    const res = await baseAPI.post(`/apply-coupon`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const removeCoupon = async data => {
  try {
    const res = await baseAPI.post(`/remove-coupon`, data, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const CartService = {
  getCartPage,
  getUserCart,
  addCartItem,
  deleteCartItem,
  applyCoupon,
  removeCoupon,
};
export default CartService;

import baseAPI from "../api/api";

const getPrivacyPolicyData = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=5`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const PrivacyPolicyService = {
  getPrivacyPolicyData,
};
export default PrivacyPolicyService;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonSlice from "./slices/commonSlice";
import homeSlice from "./slices/homeSlice";
import contactUsSlice from "./slices/contactUsSlice";
import aboutUsSlice from "./slices/aboutUsSlice";
import privacyPolicySlice from "./slices/privacyPolicySlice";
import termsConditionsSlice from "./slices/termsConditionsSlice";
import registerUserSlice from "./slices/registerUserSlice";
import userSlice from "./slices/userSlice";
import productSlice from "./slices/productSlice";
import guestCartSlice from "./slices/guestCartSlice";
import placeOrderSlice from "./slices/placeOrderSlice";

const rootReducer = combineReducers({
  commonStore: commonSlice,
  registerStore: registerUserSlice,
  userStore: userSlice,
  homeStore: homeSlice,
  contactUsStore: contactUsSlice,
  aboutUsStore: aboutUsSlice,
  privacyPolicyStore: privacyPolicySlice,
  termsConditionStore: termsConditionsSlice,
  productStore: productSlice,
  guestCartStore: guestCartSlice,
  placeOrderStore: placeOrderSlice,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

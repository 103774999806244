import React from "react";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Footer = () => {
  const { i18n, t } = useTranslation("global");
  const { common } = useSelector(state => state.commonStore);
  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <div className="footer-logo">
                <img src={common?.footer_logo} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-2">
              <h5>{t("explore")}</h5>
              <ul className="footerlinks">
                <li>
                  <Link to="/">{t("home")}</Link>
                </li>
                <li>
                  <Link to="/about-us">{t("aboutus")}</Link>
                </li>
                <li>
                  <Link to="/contact-us">{t("contactus")}</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">{t("tandc")}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h5>{t("products")}</h5>
              <ul className="footerlinks">
                {common?.category?.map(c => {
                  return (
                    <li key={c.id}>
                      <Link to={c.name_en.toLowerCase()}>
                        {i18n.language == "en" ? c.name_en : c.name_ar}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>{t("contactus")}</h5>
              <ul className="contact-info">
                <li className="location-name">UAE</li>
                <li>{common?.uae_address}</li>
                <li>
                  <a
                    className="text-decoration-none text-light"
                    href={`tel:${common?.uae_phone_number_1}`}
                  >
                    <span>+{common?.uae_phone_number_1}</span>{" "}
                  </a>
                  <a
                    className="text-decoration-none text-light"
                    href={`tel:${common?.uae_phone_number_2}`}
                  >
                    <span>+{common?.uae_phone_number_2}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none text-light"
                    href={`mailto:${common?.uae_email}`}
                  >
                    {common?.uae_email}
                  </a>
                </li>
              </ul>
              <div className="social-info">
                <Link to={common?.uae_instagram} target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link to={common?.uae_facebook} target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link to={common?.uae_vimeo} target="_blank">
                  <i className="fa-brands fa-vimeo-v"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <h5>&nbsp;</h5>
              <ul className="contact-info">
                <li className="location-name">KSA</li>
                <li>{common?.ksa_address}</li>
                <li>
                  <a
                    href={`tel:${common?.ksa_phone_number_1}`}
                    className="text-decoration-none text-light"
                  >
                    <span>+{common?.ksa_phone_number_1}</span>{" "}
                  </a>
                  <a
                    className="text-decoration-none text-light"
                    href={`tel:${common?.ksa_phone_number_2}`}
                  >
                    <span>+{common?.ksa_phone_number_2}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none text-light"
                    href={`mailto:${common?.ksa_email}`}
                  >
                    {common?.ksa_email}
                  </a>
                </li>
              </ul>
              <div className="social-info">
                <Link to={common?.ksa_instagram} target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link to={common?.ksa_facebook} target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link to={common?.ksa_vimeo} target="_blank">
                  <i className="fa-brands fa-vimeo-v"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          &copy; 2024 {common?.website_name_en}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

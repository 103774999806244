import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/responsive.css";
import "./assets/css/main.scss";
import Layout from "./layout/Layout";
import AppRoutes from "./routes/AppRoutes";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCommonData } from "./store/slices/commonSlice";
import { getUserCart } from "./store/slices/guestCartSlice";

// import "../node_modules/jquery/dist/jquery.min.js";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
function App() {
  const { i18n, t } = useTranslation("global");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getCommonData());
    if (token) {
      dispatch(getUserCart());
    }
  }, [dispatch]);

  useEffect(() => {
    let htmlEl = document.querySelector("html");
    i18n.language == "ar"
      ? htmlEl.setAttribute("dir", "rtl")
      : htmlEl.setAttribute("dir", "ltr");
    i18n.language == "ar"
      ? htmlEl.setAttribute("lang", "ar")
      : htmlEl.setAttribute("lang", "en");
  }, []);

  return (
    <>
      <Layout>
        <AppRoutes />
      </Layout>
    </>
  );
}

export default App;

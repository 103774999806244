import React from "react";
import { Navigate } from "react-router-dom";
let token = localStorage.getItem("token");
let email = localStorage.getItem("email");

const PrivateRoutes = ({ children }) => {
  return token && email ? children : <Navigate to="/" />;
};

export default PrivateRoutes;

import Swal from "sweetalert2";
import baseAPI from "../api/api";
import { authHeader } from "../helpers/authHeaders";

const getProductPage = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=8`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getProductDetailPage = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=9`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getProductDetailData = async sku => {
  try {
    const res = await baseAPI.get(`/get-product-details?sku=${sku}`, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getProducts = async ({ id, params, brand_id }) => {
  try {
    if (brand_id) {
      const res = await baseAPI.get(
        `/get-products?category_id=${id}&brand_id=${brand_id}&${params}`,
        {
          headers: authHeader(),
        }
      );
      return res.data;
    } else {
      const res = await baseAPI.get(
        `/get-products?category_id=${id}&${params}`,
        {
          headers: authHeader(),
        }
      );
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getProductsSearch = async data => {
  try {
    const res = await baseAPI.get(`/get-products?search=${data}`, {
      headers: authHeader(),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const addProductRating = async data => {
  try {
    const res = await baseAPI.post(`/add-product-rating`, data, {
      headers: authHeader(),
    });
    Swal.fire({
      position: "center",
      title: "Thank You For Rating !!!",
      showConfirmButton: false,
      icon: "success",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const ProductService = {
  getProductPage,
  getProductDetailPage,
  getProductDetailData,
  getProducts,
  getProductsSearch,
  addProductRating,
};
export default ProductService;

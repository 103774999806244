import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CommonService from "../../services/CommonService";

export const getCommonData = createAsyncThunk(
  "common/getCommonData",
  async () => {
    try {
      const res = await CommonService.getCommonData();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  common: null,
  toggleDropdown: false,
  searchShow: false,
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    dropdownToggle: state => {
      state.toggleDropdown = !state.toggleDropdown;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCommonData.pending, state => {
        state.loading = true;
      })
      .addCase(getCommonData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.common = payload;
      })
      .addCase(getCommonData.rejected, state => {
        state.loading = false;
      });
  },
});
export const { dropdownToggle } = commonSlice.actions;
export default commonSlice.reducer;

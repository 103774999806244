import React, { useDebugValue, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import arrowWhite from "../../assets/images/arrow.svg";
import arrowBlack from "../../assets/images/arrow-dark.svg";
import ChangePassword from "../../components/Forms/ChangePassword";
import AddAddress from "../../components/Forms/AddAddress";
import EditAddress from "../../components/Forms/EditAddress";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserWishlist,
  getProfilePage,
  getUserProfile,
  handleFavorite,
  handleOrderFavorite,
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import StarsRating from "react-star-rate";
import { Link } from "react-router-dom";
import EditProfile from "../../components/Forms/EditProfile";
import HelmetBase from "../../components/Helmet";
import { RWebShare } from "react-web-share";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

const Profile = () => {
  const { i18n, t } = useTranslation("global");

  const [showChangePasswordModal, setChangePasswordModalShow] = useState(false);
  const [showAddAddressModal, setAddAddressModalShow] = useState(false);
  const [showEditAddressModal, setEditAddressModalShow] = useState(false);
  const [showEditProfileModal, setEditProfileModalShow] = useState(false);
  const [addressId, setAddressId] = useState(null);

  const handleChangePasswordClose = () => setChangePasswordModalShow(false);
  const handleChangePasswordShow = () => setChangePasswordModalShow(true);

  const handleAddAddressClose = () => setAddAddressModalShow(false);
  const handleAddAddressShow = () => setAddAddressModalShow(true);

  const handleEditAddressClose = () => setEditAddressModalShow(false);
  const handleEditAddressShow = id => {
    setEditAddressModalShow(true);
    setAddressId(id);
  };

  const handleEditProfileClose = () => setEditProfileModalShow(false);
  const handleEditProfileShow = () => setEditProfileModalShow(true);

  const dispatch = useDispatch();

  const { user, profilePageObj, wishlist, orderList } = useSelector(
    state => state.userStore
  );

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfilePage());
  }, []);

  const favorite = (id, favorite) => {
    let formData = new FormData();

    if (favorite === 1) {
      dispatch(handleFavorite(id));

      formData.append("product_id", id);
      formData.append("status", 0);
    }
    dispatch(addUserWishlist(formData));
  };

  // const orderFavorite = (productId, favorite) => {
  //   let orderArr;
  //   let wishArr;
  //   let formData = new FormData();

  //   if (favorite === 1) {
  //     orderArr = orderList?.map(item => {
  //       return item.product_id === productId
  //         ? { ...item, is_favorite: 0 }
  //         : item;
  //     });
  //     wishArr = wishlist?.filter(item => {
  //       return item.id != productId;
  //     });
  //     dispatch(handleOrderFavorite(orderArr));
  //     // dispatch(handleFavorite(wishArr));
  //     formData.append("product_id", productId);
  //     formData.append("status", 0);
  //   } else {
  //     orderArr = orderList?.map(item => {
  //       return item.product_id === productId
  //         ? { ...item, is_favorite: 1 }
  //         : item;
  //     });

  //     formData.append("product_id", productId);
  //     formData.append("status", 1);

  //     dispatch(handleOrderFavorite(orderArr));
  //     // dispatch(handleFavorite(wishArr));
  //   }
  //   dispatch(addUserWishlist(formData));
  // };

  return (
    <>
      <HelmetBase
        title={profilePageObj?.meta_title}
        name={profilePageObj?.name_en}
        description={profilePageObj?.meta_description}
      />
      {/* <!-- Page Heading --> */}
      <section className="section-page-heading">
        <div className="page-heading-watermark">{t("profile")}</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                <h5>
                  {i18n.language
                    ? profilePageObj?.heading_en
                    : profilePageObj?.heading_ar}
                </h5>
                <h1>{t("profile")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Heading --> */}

      {/* <!-- Section Profile --> */}
      <section className="section-registration">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-5">
              <div className="user-basic-info text-uppercase">
                <h3 className="mt-0">
                  {t("hello")} {user?.name}!
                </h3>
                <ul>
                  <li>
                    <span>{t("name")}</span>
                    {user?.name}
                  </li>
                  <li>
                    <span>{t("email")}</span>
                    {user?.email}
                  </li>
                  <li>
                    <span>{t("mobileNumber")}</span>+971 {user?.phone_number}
                  </li>
                </ul>
                <div>
                  <button
                    className="btn btn-outline-light me-4 arrow-animation"
                    onClick={handleEditProfileShow}
                  >
                    {t("edit")} <span class="arrow"></span>
                  </button>
                  <button
                    href="#"
                    className="btn btn-outline-light arrow-animation"
                    data-bs-toggle="modal"
                    data-bs-target="#changePassword"
                    onClick={handleChangePasswordShow}
                  >
                    {t("changePassword")} <span class="arrow"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="row align-items-center">
                {user?.addresss?.map((address, index) => {
                  return (
                    <div className="col-sm-4" key={index}>
                      <div className="address-box">
                        <h5 className="text-uppercase">
                          {t("address")} {index + 1}
                        </h5>
                        <p>
                          {address?.apartment_number}, {address?.building_name},{" "}
                          {address?.street_name}, {address?.area}
                        </p>
                        <button
                          onClick={() => handleEditAddressShow(address?.id)}
                          className="btn btn-outline-light arrow-animation w-100"
                        >
                          {t("edit")} <span class="arrow"></span>
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="col-sm-4">
                  <div className="address-box d-flex align-items-center justify-content-center">
                    <button
                      className="btn btn-outline-light w-100 px-0 arrow-animation"
                      data-bs-toggle="modal"
                      data-bs-target="#addnewAddress"
                      onClick={handleAddAddressShow}
                    >
                      {t("add")} {t("address")} <span class="arrow"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Profile --> */}

      {/* <!-- Section Wishlist --> */}
      <section className="section-wishlist">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="title mb-4 text-white">
                <h2 className="text-uppercase">{t("wishlist")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {wishlist?.length > 0 ? (
                <Swiper
                  spaceBetween={50}
                  modules={[Navigation, Autoplay]}
                  slidesPerView={1}
                  dir={i18n.language == "en" ? "ltr" : "rtl"}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {wishlist?.map((wish, index) => {
                    let shareData = {
                      url: `/product-detail/${wish.sku}`,
                    };
                    return (
                      <SwiperSlide key={index}>
                        <div className="prodcut-box">
                          <div className="product-image">
                            {wish?.tag == "1" ? (
                              <div className="tag"> {t("new")}</div>
                            ) : wish?.tag == "2" ? (
                              <div className="tag"> {t("sale")}</div>
                            ) : (
                              <></>
                            )}
                            <img
                              src={wish?.image}
                              className="img-fluid product-thumbnail"
                              alt=""
                            />
                            <div className="product-image-overlay">
                              <button
                                className="product-wishlist"
                                onClick={() =>
                                  favorite(wish.id, wish.is_favorite)
                                }
                              >
                                <i
                                  className={
                                    wish.is_favorite == 1
                                      ? "fa-solid fa-heart"
                                      : "fa-regular fa-heart"
                                  }
                                ></i>
                              </button>

                              <RWebShare data={shareData}>
                                <button className="product-share">
                                  <i className="fa-solid fa-share-nodes"></i>
                                </button>
                              </RWebShare>
                              <div>
                                <Link
                                  to={`/product-detail/${wish.sku}`}
                                  className="btn btn-outline-light arrow-animation"
                                >
                                  {t("seeMore")} <span class="arrow"></span>
                                </Link>
                                <Link
                                  to={`/product-detail/${wish.sku}`}
                                  className="btn btn-outline-light arrow-animation"
                                >
                                  {t("addToCart")} <span class="arrow"></span>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="product-category-rating">
                              <div className="product-category">
                                {" "}
                                {i18n.language == "en"
                                  ? wish?.category_name_en
                                  : wish?.category_name_en}
                              </div>
                              <div className="product-rating">
                                <StarsRating
                                  value={Number(wish?.avg_rating)}
                                  disabled
                                  classNamePrefix="product-rating"
                                />
                              </div>
                            </div>
                            <div className="product-title-price">
                              <h3 className="product-title">
                                <a href="#">
                                  {i18n.language == "en"
                                    ? wish?.name_en
                                    : wish?.name_ar}
                                </a>
                              </h3>
                              <div className="product-price">
                                <div className="old-price">
                                  {" "}
                                  AED {wish?.regular_price}
                                </div>
                                AED {wish?.selling_price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <h2 className="text-light">{t("noWishlistFound")}</h2>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Wishlist --> */}

      {/* <!-- Section myorders --> */}
      <section className="section-orders">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="title mb-4 text-white">
                <h2 className="text-uppercase">{t("yourOrders")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {orderList?.length > 0 ? (
                <Swiper
                  spaceBetween={50}
                  modules={[Navigation, Autoplay]}
                  slidesPerView={1}
                  dir={i18n.language == "en" ? "ltr" : "rtl"}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {orderList?.map((order, index) => {
                    let shareData = {
                      url: `/product-detail/${order.sku}`,
                    };
                    return (
                      <SwiperSlide key={index}>
                        <div className="prodcut-box">
                          <div className="product-image">
                            {order?.order_status == "1" ? (
                              <div className="delivered"> {t("pending")}</div>
                            ) : order?.order_status == "2" ? (
                              <div className="delivered">{t("confirmed")}</div>
                            ) : order?.order_status == "3" ? (
                              <div className="delivered"> {t("prepared")}</div>
                            ) : order?.order_status == "4" ? (
                              <div className="delivered"> {t("dispatch")}</div>
                            ) : (
                              <div className="delivered"> {t("cancelled")}</div>
                            )}
                            <img
                              src={order?.product_image}
                              className="img-fluid product-thumbnail"
                              alt=""
                            />
                            {/* <div className="product-image-overlay">
                          <button
                            className="product-wishlist bg-transparent"
                            onClick={() =>
                              orderFavorite(order.product_id, order.is_favorite)
                            }
                          >
                            <i
                              className={
                                order.is_favorite == 1
                                  ? "fa-solid fa-heart"
                                  : "fa-regular fa-heart"
                              }
                            ></i>
                          </button>
                          <RWebShare data={shareData}>
                            <button className="product-share bg-transparent">
                              <i className="fa-solid fa-share-nodes"></i>
                            </button>
                          </RWebShare>
                          <div>
                            <Link
                              to={`/product-details/${order.product_id}`}
                              className="btn btn-outline-light"
                            >
                              {t("seeMore")}
                            </Link>
                            <Link
                              to={`/product-details/${order.product_id}`}
                              className="btn btn-outline-light"
                            >
                              {t("addToCart")}
                            </Link>
                          </div>
                        </div> */}
                          </div>
                          <div className="product-content">
                            <div className="product-category-rating">
                              <div className="product-category">
                                {i18n.language == "en"
                                  ? order?.category_name_en
                                  : order?.category_name_en}
                              </div>
                            </div>
                            <div className="product-title-price">
                              <h3 className="product-title">
                                <Link to={`/product-detail/${order?.sku}`}>
                                  {i18n.language == "en"
                                    ? order?.name_en
                                    : order?.name_ar}
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <h2 className="text-light">No Orders Found</h2>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section myorders --> */}

      {/* Change Password Modal */}
      <Modal
        show={showChangePasswordModal}
        onHide={handleChangePasswordClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h5 className="modal-title" id="changePasswordLabel">
              {t("changePassword")}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword handleClose={handleChangePasswordClose} />
        </Modal.Body>
      </Modal>
      {/* Change Password Modal */}

      {/* Add Address Modal */}
      <Modal
        show={showAddAddressModal}
        onHide={handleAddAddressClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h5 className="modal-title" id="changePasswordLabel">
              <h5 className="modal-title" id="addnewAddressLabel">
                {t("add")} {t("new")} {t("address")}
              </h5>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAddress handleClose={handleAddAddressClose} />
        </Modal.Body>
      </Modal>
      {/* Add Address Modal */}

      {/* Edit Address Modal */}
      <Modal
        show={showEditAddressModal}
        onHide={handleEditAddressClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h5 className="modal-title" id="changePasswordLabel">
              <h5 className="modal-title" id="addnewAddressLabel">
                {t("edit")} {t("address")}
              </h5>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAddress handleClose={handleEditAddressClose} id={addressId} />
        </Modal.Body>
      </Modal>
      {/* EDIT Address Modal */}

      {/* Edit Profile Modal */}
      <Modal
        show={showEditProfileModal}
        onHide={handleEditProfileClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h5 className="modal-title" id="changePasswordLabel">
              <h5 className="modal-title" id="addnewAddressLabel">
                {t("edit")} {t("profile")}
              </h5>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProfile handleClose={handleEditProfileClose} />
        </Modal.Body>
      </Modal>
      {/* Edit Profile Modal */}
    </>
  );
};

export default Profile;

import React from "react";
import "./css/loading.css";
// import { keyframes } from "styled-components";
const Loading = () => {
  // const spin = keyframes
  // `0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`;

  const styleLoader = {
    border: "10px solid #f3f3f3",
    borderTop: "10px solid #3498db",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: `$spin 1s linear infinite`,
    "@keyframes spin": {
      "0%": {
        transform: 'rotate("0deg")',
      },
      "100%": {
        transform: 'rotate("360deg")',
      },
    },
  };

  return (
    <div className="d-flex d-flex justify-content-center align-items-center my-5">
      <div className="text-light my-5 loader" style={styleLoader}></div>
    </div>
  );
};

export default Loading;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PrivacyPolicyService from "../../services/PrivacyPolicyService";

export const getPrivacyPolicyData = createAsyncThunk(
  "privacypolicy/getPrivacyPolicyData",
  async () => {
    try {
      const res = await PrivacyPolicyService.getPrivacyPolicyData();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  privacyPolicyObj: null,
};

const privacyPolicySlice = createSlice({
  name: "privacypolicy",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getPrivacyPolicyData.pending, state => {
        state.loading = true;
      })
      .addCase(getPrivacyPolicyData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.privacyPolicyObj = payload;
      })
      .addCase(getPrivacyPolicyData.rejected, state => {
        state.loading = false;
      });
  },
});
export default privacyPolicySlice.reducer;

import React from "react";

import * as Yup from "yup";
// Import Images
import arrowWhite from "../../assets/images/arrow.svg";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../store/slices/registerUserSlice";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Email = () => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    email: Yup.string()
      .email(t("enteravalidemail"))
      .matches(emailRegex, t("emailnotallowed"))
      .trim()
      .required(t("pleaseenteryouremail"))
      .max(35),
  });

  const dispatch = useDispatch();

  let websiteURL = process.env.REACT_APP_URL;

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formValidations,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append("email", values.email);
      formData.append("link", websiteURL);
      localStorage.setItem("email", values.email);
      dispatch(forgetPassword(formData));
      setSubmitting(false);
      resetForm();
    },
  });

  return (
    <>
      {/* <!-- Page Heading --> */}
      <section className="section-page-heading">
        <div className="page-heading-watermark">{t("resetPassword")}</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                <h5>&nbsp;</h5>
                <h1>&nbsp;</h1>
                <h4>&nbsp;</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Heading --> */}
      <section className="section-resetpassword">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 m-auto">
              <Form onSubmit={formik.handleSubmit}>
                <div className="form-resetpassword">
                  <div className="form-group">
                    <label>
                      {t("email")} <span>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger">{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary arrow-animation"
                      type="submit"
                    >
                      {t("send")} {t("send")} <span class="arrow"></span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Email;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TermsConditionService from "../../services/TermsConditionsService";

export const getTermsConditionData = createAsyncThunk(
  "termscondition/getTermsConditionData",
  async () => {
    try {
      const res = await TermsConditionService.getTermsConditionData();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  termsConditionObj: null,
};

const termsConditionsSlice = createSlice({
  name: "termscondition",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getTermsConditionData.pending, state => {
        state.loading = true;
      })
      .addCase(getTermsConditionData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.termsConditionObj = payload;
      })
      .addCase(getTermsConditionData.rejected, state => {
        state.loading = false;
      });
  },
});
export default termsConditionsSlice.reducer;

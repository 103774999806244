import baseAPI from "../api/api";

const login = async data => {
  try {
    const res = await baseAPI.post(`/login`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const AuthService = {
  login,
};
export default AuthService;

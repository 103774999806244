import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addAddress } from "../../store/slices/userSlice";
import { addSessionAddress } from "../../store/slices/guestCartSlice";
import arrowWhite from "../../assets/images/arrow.svg";
import arrowBlack from "../../assets/images/arrow-dark.svg";
const AddAddress = ({ handleClose }) => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    apartmentNumber: Yup.string()
      .trim()
      .required(t("apartmentNumberisrequired")),
    buildingName: Yup.string().trim().required(t("buildingNameisrequired")),
    streetName: Yup.string().trim().required(t("streetNameisrequired")),
    area: Yup.string().trim().required(t("areaisrequired")),
  });

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      apartmentNumber: "",
      buildingName: "",
      streetName: "",
      area: "",
    },
    validationSchema: formValidations,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (token) {
          let formData = new FormData();
          formData.append("apartment_number", values.apartmentNumber);
          formData.append("building_name", values.buildingName);
          formData.append("street_name", values.streetName);
          formData.append("area", values.area);

          dispatch(addAddress(formData));
          setSubmitting(false);
          resetForm();
          handleClose();
        } else {
          dispatch(addSessionAddress(values));
          handleClose();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("apartmentnumber")}</label>
              <input
                type="text"
                className="form-control"
                name="apartmentNumber"
                value={formik.values.apartmentNumber}
                onChange={formik.handleChange}
              />
              {formik.touched.apartmentNumber &&
                formik.errors.apartmentNumber && (
                  <div className="text-danger">
                    {formik.errors.apartmentNumber}
                  </div>
                )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("buildingName")}</label>
              <input
                type="text"
                className="form-control"
                name="buildingName"
                value={formik.values.buildingName}
                onChange={formik.handleChange}
              />
              {formik.touched.buildingName && formik.errors.buildingName && (
                <div className="text-danger">{formik.errors.buildingName}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("streetName")}</label>
              <input
                type="text"
                className="form-control"
                name="streetName"
                value={formik.values.streetName}
                onChange={formik.handleChange}
              />
              {formik.touched.streetName && formik.errors.streetName && (
                <div className="text-danger">{formik.errors.streetName}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("area")}</label>
              <input
                type="text"
                className="form-control"
                name="area"
                value={formik.values.area}
                onChange={formik.handleChange}
              />
              {formik.touched.area && formik.errors.area && (
                <div className="text-danger">{formik.errors.area}</div>
              )}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {t("add")} {t("address")}{" "}
          <span>
            <img src={arrowWhite} alt="" />
          </span>
        </button>
      </Form>
    </>
  );
};

export default AddAddress;

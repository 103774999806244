import baseAPI from "../api/api";

const getTermsConditionData = async () => {
  try {
    const res = await baseAPI.get(`/get-cms-page?id=4`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const TermsConditionService = {
  getTermsConditionData,
};
export default TermsConditionService;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/UserService";
import Swal from "sweetalert2";

export const getProfilePage = createAsyncThunk(
  "user/getProfilePage",
  async () => {
    try {
      const res = await UserService.getProfilePage();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async () => {
    try {
      const res = await UserService.getUserProfile();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async data => {
    try {
      const res = await UserService.updateUserProfile(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addUserWishlist = createAsyncThunk(
  "user/addUserWishlist",
  async data => {
    try {
      const res = await UserService.addUserWishlist(data);
      return res.data;
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Product is already in Wishlist",
        showConfirmButton: false,
        timer: 3000,
      });
      console.log(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async data => {
    try {
      const res = await UserService.changePassword(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAddress = createAsyncThunk("user/getAddress", async () => {
  try {
    const res = await UserService.getAddress();
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const getAddressById = createAsyncThunk(
  "user/getAddressById",
  async id => {
    try {
      const res = await UserService.getAddressById(id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addAddress = createAsyncThunk(
  "user/addAddress",
  async (data, thunkAPI) => {
    try {
      const res = await UserService.addAddress(data);
      if (res) {
        thunkAPI.dispatch(getUserProfile());
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const editAddress = createAsyncThunk("user/editAddress", async data => {
  try {
    // const id = data.id
    const res = await UserService.editAddress(data);
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const deleteAddress = createAsyncThunk(
  "user/deleteAddress",
  async id => {
    try {
      const res = await UserService.deleteAddress(id);
      return id;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  profilePageObj: null,
  user: null,
  addresses: [],
  address: null,
  userChangePassword: null,
  wish: null,
  wishlist: [],
  orderList: [],
  loadingWishList: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleFavorite: (state, { payload }) => {
      state.wishlist = state.wishlist?.filter(wish => wish.id !== payload);
    },
    handleOrderFavorite: (state, { payload }) => {
      state.orderList = payload;
    },
  },
  extraReducers(builder) {
    // GET  PROFILE PAGE OBJ

    builder
      .addCase(getProfilePage.pending, state => {
        state.loading = true;
      })
      .addCase(getProfilePage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profilePageObj = payload;
      })
      .addCase(getProfilePage.rejected, state => {
        state.loading = false;
      });

    // GET USER PROFILE

    builder
      .addCase(getUserProfile.pending, state => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
        state.wishlist = payload.wishlist_arr;
        state.orderList = payload.order_arr;
      })
      .addCase(getUserProfile.rejected, state => {
        state.loading = false;
      });

    // EDIT USER PROFILE

    builder
      .addCase(updateUserProfile.pending, state => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
      })
      .addCase(updateUserProfile.rejected, state => {
        state.loading = false;
      });

    // ADD USER WISHLIST

    builder
      .addCase(addUserWishlist.pending, state => {
        state.loadingWishList = true;
      })
      .addCase(addUserWishlist.fulfilled, (state, { payload }) => {
        state.loadingWishList = false;
        state.wish = payload;
      })
      .addCase(addUserWishlist.rejected, state => {
        state.loadingWishList = false;
      });

    // CHANGE USER PASSWORD

    builder
      .addCase(changePassword.pending, state => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userChangePassword = payload;
      })
      .addCase(changePassword.rejected, state => {
        state.loading = false;
      });

    // ADD ADDRESS

    builder
      .addCase(getAddress.pending, state => {
        state.loading = true;
      })
      .addCase(getAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.addresses = payload;
      })
      .addCase(getAddress.rejected, state => {
        state.loading = false;
      });

    // GET BY ID ADDRESS

    builder
      .addCase(getAddressById.pending, state => {
        state.loading = true;
      })
      .addCase(getAddressById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.address = payload;
      })
      .addCase(getAddressById.rejected, state => {
        state.loading = false;
      });

    // ADD ADDRESS

    builder
      .addCase(addAddress.pending, state => {
        state.loading = true;
      })
      .addCase(addAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.addresses = [...state.addresses, payload];
      })
      .addCase(addAddress.rejected, state => {
        state.loading = false;
      });

    //EDIT ADDRESS

    builder
      .addCase(editAddress.pending, state => {
        state.loading = true;
      })
      .addCase(editAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.address = { ...state.address, ...payload };
      })
      .addCase(editAddress.rejected, state => {
        state.loading = false;
      });

    // DELETE ADDRESS

    builder
      .addCase(deleteAddress.pending, state => {
        state.loading = true;
      })
      .addCase(deleteAddress.fulfilled, (state, { payload }) => {
        const deleteAddress = [...state.addresses].filter(
          address => address.id !== payload
        );
        return {
          ...state,
          loading: false,
          addresses: deleteAddress,
        };
      })
      .addCase(deleteAddress.rejected, state => {
        state.loading = false;
      });
  },
});

export const { handleFavorite, handleOrderFavorite } = userSlice.actions;
export default userSlice.reducer;

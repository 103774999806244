import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../../services/ProductService";

export const getProductPage = createAsyncThunk(
  "product/getProductPage",
  async () => {
    try {
      const res = await ProductService.getProductPage();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProductDetailPage = createAsyncThunk(
  "product/getProductDetailPage",
  async () => {
    try {
      const res = await ProductService.getProductDetailPage();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProductDetailData = createAsyncThunk(
  "product/getProductDetailData",
  async sku => {
    try {
      const res = await ProductService.getProductDetailData(sku);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async ({ id, params, brand_id }) => {
    try {
      const res = await ProductService.getProducts({ id, params, brand_id });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProductsSearch = createAsyncThunk(
  "product/getProductsSearch",
  async data => {
    try {
      if (data.length > 0) {
        const res = await ProductService.getProductsSearch(data);
        return res.data;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  }
);

export const addProductRating = createAsyncThunk(
  "product/addProductRating",
  async data => {
    try {
      const res = await ProductService.addProductRating(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  productObj: null,
  total_records: null,
  total_pages: null,
  products: [],
  relatedProducts: [],
  productPageObj: null,
  productDetailPageObj: null,
  searchProducts: [],
  loadingSearch: false,
  ratings: null,
  searchShow: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSearchShow: (state, { payload }) => {
      state.searchShow = payload;
      if (state.searchShow == true) {
        state.searchProducts = [];
      }
    },
    addProductToWishlist: (state, { payload }) => {
      state.productObj = payload;
    },
    handleFavorite: (state, { payload }) => {
      state.products = payload;
    },
    handleRelatedFavorite: (state, { payload }) => {
      state.relatedProducts = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProductPage.pending, state => {
        state.loading = true;
      })
      .addCase(getProductPage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.productPageObj = payload;
      })
      .addCase(getProductPage.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getProductDetailPage.pending, state => {
        state.loading = true;
      })
      .addCase(getProductDetailPage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.productDetailPageObj = payload;
      })
      .addCase(getProductDetailPage.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getProductDetailData.pending, state => {
        state.loading = true;
      })
      .addCase(getProductDetailData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.productObj = payload;
        state.relatedProducts = payload.related_products;
      })
      .addCase(getProductDetailData.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getProducts.pending, state => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.products = payload?.data;
        state.total_records = payload?.total_records;
        state.total_pages = payload?.total_pages;
      })
      .addCase(getProducts.rejected, state => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(getProductsSearch.pending, state => {
        state.loadingSearch = true;
      })
      .addCase(getProductsSearch.fulfilled, (state, { payload }) => {
        state.loadingSearch = false;
        state.searchProducts = payload;
      })
      .addCase(getProductsSearch.rejected, state => {
        state.loadingSearch = false;
        state.error = true;
      });

    builder
      .addCase(addProductRating.pending, state => {
        state.loadingSearch = true;
      })
      .addCase(addProductRating.fulfilled, (state, { payload }) => {
        state.loadingSearch = false;
        state.ratings = payload;
      })
      .addCase(addProductRating.rejected, state => {
        state.loadingSearch = false;
        state.error = true;
      });
  },
});
export const {
  addProductToWishlist,
  handleFavorite,
  handleRelatedFavorite,
  setSearchShow,
} = productSlice.actions;
export default productSlice.reducer;

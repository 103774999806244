import baseAPI from "../api/api";

const getCommonData = async () => {
  try {
    const res = await baseAPI.get(`/get-system-configuration`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const CommonService = {
  getCommonData,
};
export default CommonService;

import React from "react";
import image404 from "../../assets/images/404.png";
const NotFound = () => {
  return (
    <section className="m-5 text-light">
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={image404} alt="404" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;

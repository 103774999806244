import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import ar from "./locale/ar.json";
const lang = localStorage.getItem("language");

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang ? lang : "en",
  fallbackLng: lang ? lang : "en",
  resources: {
    en: {
      global: en,
    },
    ar: {
      global: ar,
    },
  },
});

export default i18next;

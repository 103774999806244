import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import CartService from "../../services/CartService";
import _ from "lodash";
export const getCartPage = createAsyncThunk(
  "guestcart/getCartPage",
  async () => {
    try {
      const res = await CartService.getCartPage();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserCart = createAsyncThunk(
  "guestcart/getUserCart",
  async () => {
    try {
      const res = await CartService.getUserCart();

      let data = res.data;

      if (data?.coupon_code != null) {
        let formdata = new FormData();

        formdata.append("cart_id", data.cart_id);
        formdata.append("coupon_code", data.coupon_code);
        CartService.applyCoupon(formdata);
      }

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCartItem = createAsyncThunk(
  "guestcart/addCartItem",
  async (data, thunkAPI) => {
    try {
      const res = await CartService.addCartItem(data);
      thunkAPI.dispatch(getUserCart());
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCartItem = createAsyncThunk(
  "guestcart/deleteCartItem",
  async (payload, thunkAPI) => {
    try {
      const { data, product_id, color_id, size_id } = payload;
      const res = await CartService.deleteCartItem(data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Product is Removed From Cart",
        showConfirmButton: false,
        timer: 3000,
      });
      await thunkAPI.dispatch(getUserCart());
      return { product_id, color_id, size_id };
    } catch (error) {
      console.log(error);
    }
  }
);

export const applyCoupon = createAsyncThunk(
  "guestcart/applyCoupon",
  async (payload, thunkAPI) => {
    try {
      const res = await CartService.applyCoupon(payload);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Coupon verfired successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        await thunkAPI.dispatch(getUserCart());
      }

      return res.data;
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter valid coupon",
        showConfirmButton: false,
        timer: 3000,
      });
      console.log(error);
    }
  }
);

export const removeCoupon = createAsyncThunk(
  "guestcart/removeCoupon",
  async (payload, thunkAPI) => {
    try {
      const res = await CartService.removeCoupon(payload);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Coupon removed successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      await thunkAPI.dispatch(getUserCart());
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  guestcart: sessionStorage.getItem("cart")
    ? JSON.parse(sessionStorage.getItem("cart"))
    : [],
  total: sessionStorage.getItem("total") ? sessionStorage.getItem("total") : 0,
  address: sessionStorage.getItem("address")
    ? JSON.parse(sessionStorage.getItem("address"))
    : [],
  cartPage: null,
  cart: [],
  item: {},
  cartCount: 0,
  related_items: [],
  total_amount: "",
  sub_total_amount: "",
  shipping_amount: "",
  cart_id: null,
  coupon: null,
  coupon_code: sessionStorage.getItem("coupon")
    ? sessionStorage.getItem("coupon")
    : null,
  discount: sessionStorage.getItem("discount")
    ? JSON.parse(sessionStorage.getItem("discount"))
    : 0,
  guest_sub_total: sessionStorage.getItem("sub_total")
    ? sessionStorage.getItem("sub_total")
    : 0,
  coupon_amount: null,
};

const guestCartSlice = createSlice({
  name: "guestcart",
  initialState,
  reducers: {
    addProduct: (state, { payload }) => {
      state.guestcart = [...state.guestcart, payload];

      let ttl = 0;

      for (var i = 0; i < state.guestcart.length; i++) {
        let price =
          Number(state.guestcart[i].selling_price) *
          Number(state.guestcart[i].quantity);

        ttl = ttl + price;
      }
      state.guest_sub_total = ttl;
      let tot = 0;
      if (state.coupon_code && state.discount) {
        tot = (
          (Number(state.guest_sub_total) * Number(state.discount)) /
          100
        ).toFixed(2);
      }

      state.total = Number(state.guest_sub_total) - Number(tot).toFixed(2);

      sessionStorage.setItem("cart", JSON.stringify(state.guestcart));
      sessionStorage.setItem("sub_total", state.guest_sub_total);
      sessionStorage.setItem("total", state.total);
    },
    removeFromCart: (state, { payload }) => {
      state.guestcart = state.guestcart.filter(item => item.id !== payload.id);

      let ttl = 0;

      for (var i = 0; i < state.guestcart.length; i++) {
        let price =
          Number(state.guestcart[i].selling_price) *
          Number(state.guestcart[i].quantity);

        ttl = ttl + price;
      }
      state.guest_sub_total = ttl;
      let tot = 0;

      if (state.coupon_code && state.discount) {
        tot = (
          (Number(state.guest_sub_total) * Number(state.discount)) /
          100
        ).toFixed(2);
      }

      state.total = Number(state.guest_sub_total) - Number(tot).toFixed(2);

      sessionStorage.setItem("cart", JSON.stringify(state.guestcart));
      sessionStorage.setItem("sub_total", state.guest_sub_total);
      sessionStorage.setItem("total", state.total);

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Product is Remove From Cart",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    addSessionAddress: (state, { payload }) => {
      state.address = [
        ...state.address,
        { id: state.address.length - 1 + 1, ...payload },
      ];
      sessionStorage.setItem("address", JSON.stringify(state.address));
    },
    removeAllCartItems: state => {
      state.guestcart = [];
      state.guest_sub_total = 0;
      state.total = 0;
      state.coupon_code = null;
      state.discount = 0;
    },

    quantityChange: (state, { payload }) => {
      let findItem = state.guestcart.find(item => item.id == payload.id);

      state.guestcart = state.guestcart.filter(item => item.id !== findItem.id);

      findItem = {
        ...findItem,
        quantity: payload.quantity,
      };

      state.guestcart = [...state.guestcart, findItem];

      let ttl = 0;

      for (var i = 0; i < state.guestcart.length; i++) {
        let price =
          Number(state.guestcart[i].selling_price) *
          Number(state.guestcart[i].quantity);

        ttl = ttl + price;
      }

      state.guest_sub_total = ttl;
      // let tot = 0;

      // if (state.coupon_code && state.discount) {
      //   tot = (
      //     (Number(state.guest_sub_total) * Number(state.discount)) /
      //     100
      //   ).toFixed(2);
      // }

      // state.total = Number(state.guest_sub_total) - Number(tot).toFixed(2);
      state.total = state.guest_sub_total;
      state.coupon_code = null;
      state.discount = 0;
      sessionStorage.setItem("cart", JSON.stringify(state.guestcart));
      sessionStorage.setItem("sub_total", state.guest_sub_total);
      sessionStorage.setItem("total", state.total);
    },
    handleFavorite: (state, { payload }) => {
      state.related_items = payload;
    },
    guestCouponApply: (state, { payload }) => {
      state.discount = payload.discount;
      let ttl = (
        (Number(state.guest_sub_total) * Number(payload.discount)) /
        100
      ).toFixed(2);

      state.guest_sub_total = state.total;
      state.discount_amount = Number(ttl).toFixed(2);
      state.total = (Number(state.total) - ttl).toFixed(2);
      state.coupon_code = payload.couponCode;

      sessionStorage.setItem("sub_total", state.guest_sub_total);
      sessionStorage.setItem("total", state.total);
      sessionStorage.setItem("discount", state.discount);
      sessionStorage.setItem("coupon", state.coupon_code);
      // statetotal
    },
    guestRemoveCoupon: (state, { payload }) => {
      state.discount = 0;
      state.coupon_code = null;
      state.guest_sub_total = Number(state.guest_sub_total);
      state.total = Number(state.guest_sub_total);
      sessionStorage.removeItem("discount");
      sessionStorage.removeItem("coupon");
      sessionStorage.setItem("sub_total", state.guest_sub_total);
      sessionStorage.setItem("total", state.total);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Coupon removed successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCartPage.pending, state => {
        state.loading = true;
      })
      .addCase(getCartPage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartPage = payload;
      })
      .addCase(getCartPage.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getUserCart.pending, state => {
        state.loading = true;
      })
      .addCase(getUserCart.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cart = payload?.cart_items;
        state.related_items = payload?.related_items;
        state.total_amount = payload?.total_amount;
        state.sub_total_amount = payload?.sub_total_amount;
        state.shipping_amount = payload?.shipping_amount;
        state.cart_id = payload?.cart_id;
        state.discount = payload?.discount;
        state.coupon_code = payload?.coupon_code;
        state.coupon_amount = payload?.coupon_amount;

        if (_.isEmpty(payload?.coupon_code)) {
          sessionStorage.setItem("coupon", payload?.coupon_code);
        }
      })
      .addCase(getUserCart.rejected, state => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(addCartItem.pending, state => {
        state.loading = true;
      })
      .addCase(addCartItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cart = payload?.cart_items;
      })
      .addCase(addCartItem.rejected, state => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(deleteCartItem.pending, state => {
        state.loading = true;
      })
      .addCase(deleteCartItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.cart = state.cart.filter(
        //   item =>
        //     item.product_id != payload.product_id &&
        //     item.color_id != payload.color_id &&
        //     item.size_id != payload.size_id
        // );
      })
      .addCase(deleteCartItem.rejected, state => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(applyCoupon.pending, state => {
        state.loading = true;
      })
      .addCase(applyCoupon.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.coupon = payload;
      })
      .addCase(applyCoupon.rejected, state => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(removeCoupon.pending, state => {
        state.loading = true;
      })
      .addCase(removeCoupon.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.coupon = null;
        state.coupon_code = null;
        sessionStorage.removeItem("coupon");
      })
      .addCase(removeCoupon.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  addProduct,
  removeFromCart,
  addSessionAddress,
  quantityChange,
  handleFavorite,
  guestCouponApply,
  guestRemoveCoupon,
  removeAllCartItems,
} = guestCartSlice.actions;
export default guestCartSlice.reducer;

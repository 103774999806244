import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RegisterService from "../../services/RegisterService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { dropdownToggle } from "./commonSlice";

export const registerPage = createAsyncThunk(
  "registeruser/registerPage",
  async () => {
    try {
      const res = await RegisterService.registerPage();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "registeruser/registerUser",
  async data => {
    try {
      const res = await RegisterService.registerUser(data);
      if (res.status == 1) {
        localStorage.setItem("email", res?.data?.email);
        localStorage.setItem("name", res?.data?.name);
        localStorage.setItem("token", res?.data?.token);
        window.location.reload();
        window.location.href = "/";
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "registeruser/forgetPassword",
  async data => {
    try {
      const res = await RegisterService.forgetPassword(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "registeruser/resetPassword",
  async data => {
    try {
      const res = await RegisterService.resetPassword(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  user: null,
  registerPageObj: null,
  forgetPasswordObj: null,
  resetPasswordObj: null,
};

const registerUserSlice = createSlice({
  name: "registeruser",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(registerPage.pending, state => {
        state.loading = true;
      })
      .addCase(registerPage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.registerPageObj = payload;
      })
      .addCase(registerPage.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(registerUser.pending, state => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
      })
      .addCase(registerUser.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(forgetPassword.pending, state => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.forgetPasswordObj = payload;
      })
      .addCase(forgetPassword.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(resetPassword.pending, state => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.resetPasswordObj = payload;
      })
      .addCase(resetPassword.rejected, state => {
        state.loading = false;
      });
  },
});
export default registerUserSlice.reducer;

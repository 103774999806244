import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../store/slices/userSlice";
import arrowWhite from "../../assets/images/arrow.svg";
import arrowBlack from "../../assets/images/arrow-dark.svg";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EditProfile = ({ handleClose }) => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    name: Yup.string().trim().required(t("nameisrequired")),
    email: Yup.string()
      .email(t("enteravalidemail"))
      .matches(emailRegex, t("emailnotallowed"))
      .trim()
      .required(t("pleaseenteryouremail"))
      .max(35),
    mobileNumber: Yup.string()
      .required(t("mobilenumberisRequired"))
      .trim()
      .matches(phoneRegExp, t("phonenumberisnotvalid"))
      .min(9, t("adddigitmobilenumber"))
      .max(12, t("adddigitmobilenumber1")),
  });

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.userStore);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name,
      email: user?.email,
      mobileNumber: user?.phone_number,
    },
    validationSchema: formValidations,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();

      formData.append("email", values.email);
      formData.append("name", values.name);
      formData.append("phone_number", values.mobileNumber);

      dispatch(updateUserProfile(formData));
      setSubmitting(false);
      resetForm();
      handleClose();
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("name")}</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("email")}</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>{t("mobileNumber")}</label>
              <input
                type="phone"
                className="form-control"
                name="mobileNumber"
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                <div className="text-danger">{formik.errors.mobileNumber}</div>
              )}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {t("edit")} {t("profile")}{" "}
          <span>
            <img src={arrowWhite} alt="" />
          </span>
        </button>
      </Form>
    </>
  );
};

export default EditProfile;

import { useContext, createContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { i18n, t } = useTranslation("global");

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [error, setError] = useState(null);

  // const navigate = useNavigate();

  useEffect(() => {
    setUser({
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
    });
  }, []);

  const loginAction = async data => {
    try {
      setError(null);
      let formdata = new FormData();

      formdata.append("email", data.email);
      formdata.append("password", data.password);

      const res = await AuthService.login(formdata);

      if (res && res.status == 1 && res.data) {
        setUser(res.data);
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("name", res.data.name);
        sessionStorage.removeItem("cart");
        sessionStorage.removeItem("total");
        sessionStorage.removeItem("address");
        sessionStorage.removeItem("sub_total");
        sessionStorage.removeItem("discount");
        sessionStorage.removeItem("coupon");
        window.location.reload();
        return;
      } else {
        setError(t("invalidCredentials"));
      }
    } catch (err) {
      setError(t("invalidCredentials"));
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    sessionStorage.removeItem("coupon");
    window.location.reload();
    // navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ token, user, error, loginAction, logOut, setError }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { changePassword } from "../../store/slices/userSlice";
import arrowWhite from "../../assets/images/arrow.svg";
import arrowBlack from "../../assets/images/arrow-dark.svg";
const ChangePassword = ({ handleClose }) => {
  const { i18n, t } = useTranslation("global");

  const formValidations = Yup.object({
    old_password: Yup.string()
      .trim()
      .required(t("oldPasswordisrequired"))
      .min(8, t("minimumCharactersRequired")),
    password: Yup.string()
      .trim()
      .required(t("newPasswordisrequired"))
      .min(8, t("minimumCharactersRequired")),
    confirmPassword: Yup.string()
      .required(t("confirmPasswordrequired"))
      .oneOf([Yup.ref("password"), null], t("passwordsmustmatch")),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: formValidations,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        let formData = new FormData();
        formData.append("old_password", values.old_password);
        formData.append("password", values.password);
        dispatch(changePassword(formData));
        setSubmitting(false);
        resetForm();
        handleClose();
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                {t("old")} {t("password")}
              </label>
              <input
                type="password"
                className="form-control"
                name="old_password"
                value={formik.values.old_password}
                onChange={formik.handleChange}
              />
              {formik.touched.old_password && formik.errors.old_password && (
                <div className="text-danger">{formik.errors.old_password}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                {t("new")} {t("password")}
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="text-danger">{formik.errors.password}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                {t("new")} {t("passwordConfirmation")}
              </label>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="text-danger">
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {t("changePassword")}{" "}
          <span>
            <img src={arrowWhite} alt="" />
          </span>
        </button>
      </Form>
    </>
  );
};

export default ChangePassword;
